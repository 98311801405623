import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

// This is our modal component, which we will reuse within our application
const ModalWindow = props => {
  // Props
  // modalIsOpen determines whether the modal window shows or not
  // setIsOpen is the function to toggle it
  // Children is the HTML content to display inside the modal
  const { show = false, toggle = () => {}, children } = props;

  // The function called to close the modal
  const closeModal = () => {
    toggle(false);
  };

  // The custom style for the modal
  // Black background overlay, and centered content
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      padding: 0,
      background: 'transparent',
      border: 0,
      transform: 'translate(-50%, -50%)'
    },
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: '#000000aa'
    }
  };

  return (
    <Modal
      isOpen={show}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      {children}
    </Modal>
  );
};

export default ModalWindow;
