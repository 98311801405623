import React from 'react';
import styled from 'styled-components';

import { Redirect } from 'react-router-dom';
import LoginPageTextSection from '../../molecules/LoginPageTextSection';
import LoginPageButtonSection from '../../organisms/LoginPageButtonSection';
import { useAuth } from '../../../functions/useAuth';

const Container = styled.div`
  background-color: ${props => props.theme.palette.background[0]};
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
`;

const LoginPage = () => {
  const auth = useAuth();

  // If the user is already logged in, we will redirect to homepage
  if (auth.user) {
    return <Redirect to="/home" />;
  }
  return (
    <Container>
      <LoginPageTextSection />
      <LoginPageButtonSection />
    </Container>
  );
};

export default LoginPage;
