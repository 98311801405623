import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  box-sizing: border-box;
`;

const PageContainer = ({ children }) => <Container>{children}</Container>;

export default PageContainer;
