import React from 'react';
import styled from 'styled-components';
import useSound from 'use-sound';

import CLICK from '../../../assets/sounds/click.wav';
import CIRCLE from '../../../assets/images/circle-icon.svg';
import CROSS from '../../../assets/images/cross-icon.svg';

export const Container = styled.div`
  min-height: 100px;
  min-width: 100px;
  background-color: ${props => {
    if (props.color !== null) {
      return props.theme.palette.primary[props.color];
    }
    return props.theme.palette.background[2];
  }};
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Image = styled.img`
  height: 90px;
`;

const Cell = ({ value = null, onClick = () => {} }) => {
  // Hooks
  const [play] = useSound(CLICK);

  const itIsAnX = value === 'x';
  const itIsAnO = value === 'o';

  let backgroundColorValue = null;

  if (itIsAnX) {
    backgroundColorValue = 1;
  } else if (itIsAnO) {
    backgroundColorValue = 0;
  }

  const onCellClick = () => {
    play();
    onClick();
  };

  return (
    <Container onClick={onCellClick} color={backgroundColorValue}>
      {itIsAnO && <Image src={CIRCLE} />}
      {itIsAnX && <Image src={CROSS} />}
    </Container>
  );
};

export default React.memo(Cell);
