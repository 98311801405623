import React, { useState } from 'react';
import styled from 'styled-components';
import Lottie from 'react-lottie';

import GoogleButton from '../../atoms/GoogleButton';
import ModalWindow from '../../atoms/ModalWindow';
import AnonLoginForm from '../../molecules/AnonLoginForm';

import WALKING_ANIMATION from '../../../assets/animations/home-page-sticker.json';
import SecondaryButton from '../../atoms/SecondaryButton';

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15vh;
  @media screen and (max-width: 480px) {
    margin-top: 10vh;
    height: 50vh;
    flex-direction: column;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1;
`;

const LottieContainer = styled.div`
  width: 60%;
  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    max-width: 80vw;
  }
`;

const ImageCaption = styled.span`
  font-size: 1em;
  color: ${props => props.theme.palette.white[1]};
  font-family: ${props => props.theme.fonts.primary};
  width: 100%;
  text-align: center;
`;

const LoginPageButtonSection = () => {
  // These are the default options for the lottie animation
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: WALKING_ANIMATION
    // rendererSettings: {
    //   preserveAspectRatio: 'xMidYMid slice',
    // },
  };

  // States
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <ContentContainer>
        <ButtonsContainer>
          <GoogleButton />
          <SecondaryButton onPress={() => setShowModal(true)} text="Skip for now" />
        </ButtonsContainer>
        <LottieContainer>
          <Lottie options={defaultOptions} />
          <ImageCaption>Remember to wash your hands!</ImageCaption>
        </LottieContainer>
      </ContentContainer>
      <ModalWindow show={showModal} toggle={setShowModal}>
        <AnonLoginForm />
      </ModalWindow>
    </>
  );
};

export default LoginPageButtonSection;
