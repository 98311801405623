import React from 'react';
import styled, { css } from 'styled-components';

export const StyledButton = styled.button`
  background-color: ${props => props.theme.palette.white[1]};
  color: ${props => props.theme.palette.background[0]};
  border: 0;
  height: 40px;
  min-width: 140px;
  padding: 0 15px;
  border-radius: 10px;
  box-shadow: ${props => `0px 5px 10px ${props.theme.palette.black[1]}`};
  font-family: ${props => props.theme.fonts.primary};
  text-transform: uppercase;
  font-size: 1em;
  outline: 0;
  cursor: pointer;
  ${props => props.secondary
    && css`
      background: transparent;
      color: palevioletred;
      box-shadow: 0 0 0 0;
      color: ${props => props.theme.palette.white[1]};
    `}
`;

const Button = ({ value, secondary, onPress }) => (
  <StyledButton
    onClick={onPress}
    secondary={secondary}
    onSubmit={onPress}
    type="button"
    aria-label="Submit Name"
  >
    {value}
  </StyledButton>
);

export default Button;
