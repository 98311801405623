import React from 'react';
import styled from 'styled-components';

import { useHistory } from 'react-router-dom';
import Button from '../../atoms/Button';

const Container = styled.div`
  margin: 3em;
  display: flex;
  flex-direction: column;
`;

const Gap = styled.div`
  height: 20px;
`;

const WarningPageButtonSection = () => {
  const history = useHistory();

  const onSubmit = () => {
    history.push('game/1');
  };

  const onBackPress = () => {
    history.goBack();
  };

  return (
    <Container>
      <Button onPress={onSubmit} value="Let's do this" />
      <Gap />
      <Button onPress={onBackPress} secondary value="Take me home" />
    </Container>
  );
};

export default WarningPageButtonSection;
