import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 200px;
  text-align: center;
  margin: 15px;
  margin-right: 0;
  flex: 1;
  cursor: pointer;
  color: ${props => props.theme.palette.white[1]};
`;

const SecondaryButton = ({ text, onPress }) => <Container onClick={onPress}>{text}</Container>;

export default SecondaryButton;
