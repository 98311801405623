import React from 'react';
import styled from 'styled-components';
import 'firebase/auth';
import { useAuth } from '../../../functions/useAuth';

const Button = styled.button`
  background-color: ${props => props.theme.palette.blue[0]};
  font-family: ${props => props.theme.fonts.primary};
  height: 50px;
  width: 200px;
  border: none;
  text-decoration: none;
  color: ${props => props.theme.palette.white[1]};
  border-radius: 4px;
  font-size: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
`;

const GoogleButton = () => {
  const auth = useAuth();

  return <Button onClick={auth.signin}>Sign in with Google</Button>;
};

export default GoogleButton;
