import React from 'react';
import Lottie from 'react-lottie';

import LOADER_ANIMATION from '../../../assets/animations/loading-animation.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: LOADER_ANIMATION
};

const Loader = () => <Lottie options={defaultOptions} />;

export default Loader;
