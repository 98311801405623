import React from 'react';
import styled from 'styled-components';

import GameBoard from '../../molecules/GameBoard';
import { useNextMove } from '../../../functions/useNextMove';
import ModalWindow from '../../atoms/ModalWindow';
import GameEndModal from '../../molecules/GameEndModal';

const Container = styled.div`
  margin: 15px 0;
`;

const GameStatusText = styled.p`
  font-size: 2.4em;
  text-align: center;
  margin: 2em 0;
  color: ${props => props.theme.palette.primary[1]};
  font-family: ${props => props.theme.fonts.primary};
`;

const GameContainer = () => {
  const INITIAL_BOARD = Array(9).fill(null);
  // Player uses x by default.
  const PLAYER_VALUE = 'x';
  const { board, onClick, status, gameEnded } = useNextMove(INITIAL_BOARD, PLAYER_VALUE);

  return (
    <Container>
      <ModalWindow show={gameEnded} toggle={() => {}}>
        <GameEndModal status={status} />
      </ModalWindow>
      <GameBoard fullSize gameData={board} onClick={onClick} />
      <GameStatusText>{status}</GameStatusText>
    </Container>
  );
};

export default GameContainer;
