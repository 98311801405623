import React, { useState } from 'react';
import styled from 'styled-components';

import Input from '../../atoms/Input';
import Button from '../../atoms/Button';
import { useAuth } from '../../../functions/useAuth';

const Container = styled.form`
  background-color: ${props => props.theme.palette.background[0]};
  padding: 30px 20px;
  border-radius: 20px;
`;

const TitleText = styled.h3`
  font-family: ${props => props.theme.fonts.secondary};
  margin: 0px;
  margin-bottom: 16px;
  color: ${props => props.theme.palette.white[1]};
  font-size: 1.4em;
`;

const SubtitleText = styled.p`
  font-size: 0.9em;
  color: ${props => props.theme.palette.white[1]};
  font-family: ${props => props.theme.fonts.secondary};
  font-weight: 100;
  margin: 0;
  margin-bottom: ${props => props.last && '20px'};
`;

const Gap = styled.div`
  height: 30px;
`;

const AnonLoginForm = () => {
  // Hooks
  const { anonSignIn } = useAuth();
  // State
  const [value, set] = useState('');

  // THe function called when we press the submit button
  const onPress = () => {
    anonSignIn(value);
  };

  // The function which sets state whenever value changes
  const onChangeText = e => {
    set(e.currentTarget.value);
  };

  return (
    <Container>
      <TitleText>Don&#39;t want to sign in?</TitleText>
      <SubtitleText>That’s ok, we understand!</SubtitleText>
      <SubtitleText last>Just gimme your name, and we’re cool</SubtitleText>
      <Input value={value} onChange={onChangeText} placeholder="Enter your name.." />
      <Gap />
      <Button onPress={onPress} value="Submit" />
    </Container>
  );
};

export default AnonLoginForm;
