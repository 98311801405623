// https://github.com/diegohaz/arc/wiki/Styling
import { reversePalette } from 'styled-theme/composer';

const theme = {};

theme.palette = {
  primary: ['#51F2E6', '#C950FF'],
  background: ['#333439', '#EDEDED4D', '#3F4045'],
  blue: ['#4C8BF5'],
  white: ['#fff', '#E3E6D9', '#ececec', '#F5F5F5'],
  black: ['#000', '#00000053']
};

theme.reversePalette = reversePalette(theme.palette);

theme.fonts = { primary: 'Montserrat', secondary: 'Open Sans' };

// theme.sizes = { maxWidth: '1100px' };

export default theme;
