import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

import { isMobile } from 'react-device-detect';
import GameCell from '../../atoms/GameCell';

import data from '../../../assets/data/index.json';

const Container = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 5px;
  ${props =>
    props.fullSize &&
    !isMobile &&
    css`
      flex: 1;
      width: 100%;
    `}
`;

const Board = props => {
  // This is the board which will be used to play the game
  // I can pass props for cell Data, and the click callback,
  // To make it functional.
  // If I don't pass data, it will render and click without a trigger.

  // Props
  // These are props I can pass from the parent to run the game
  const { gameData, onClick, fullSize } = props;

  // This is the default cell layout
  const { defaultGameData } = data.gamePage;

  const [cells, setCells] = useState(gameData || defaultGameData);

  // An effect for gameData.
  // If data changes from parent, it will relfect here.
  useEffect(() => {
    if (gameData) {
      setCells(gameData);
    }
    return () => {};
  }, [gameData]);

  const onCellClick = (value, index) => {
    if (onClick) {
      // If I have passed an onClick callback, it will trigger here.
      onClick(value, index);
    } else {
      // If we didnt pass, it will run the random game
      const orderedArray = [null, 'o', 'x', null];
      const indexInOrderedArray = orderedArray.indexOf(value);
      const newCells = [...cells];
      newCells[index] = orderedArray[indexInOrderedArray + 1];
      setCells(newCells);
    }
  };

  return (
    <Container fullSize={fullSize}>
      {cells.map((value, key) => (
        <GameCell key={key} onClick={() => onCellClick(value, key)} value={value} />
      ))}
    </Container>
  );
};

export default Board;
