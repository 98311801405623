import React from 'react';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';
import BACK_BUTTON from '../../../assets/images/back-icon.svg';

export const Image = styled.img`
  height: 30px;
  cursor: pointer;
`;

const BackButton = () => {
  const history = useHistory();

  const onClick = () => {
    history.goBack();
  };
  return (
    <>
      <Image type="button" onClick={onClick} src={BACK_BUTTON} alt="back button" />
    </>
  );
};

export default BackButton;
