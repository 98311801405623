import React from 'react';
import styled from 'styled-components';
import screenfull from 'screenfull';
import { isMobile } from 'react-device-detect';

import { useHistory } from 'react-router-dom';
import TopBar from '../../organisms/TopBar';
import PageContainer from '../../atoms/PageContainer';
import { useAuth } from '../../../functions/useAuth';
import { StyledButton } from '../../atoms/Button';
import GameBoard from '../../molecules/GameBoard';

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonWithMargin = styled(StyledButton)`
  margin-bottom: 30px;
`;

const HomePage = () => {
  const history = useHistory();
  const auth = useAuth();
  const { displayName } = auth.user;
  const greetingText = `Hi ${displayName?.split(' ')[0]}`;

  const createANewGame = () => {
    if (isMobile && screenfull.isEnabled) {
      screenfull.request();
    }
    history.push('warning');
  };

  return (
    <PageContainer>
      <TopBar logout title={greetingText} />
      <GameBoard />
      <ButtonContainer>
        <ButtonWithMargin onClick={createANewGame}>New Game</ButtonWithMargin>
        <ButtonWithMargin>Leaderboard</ButtonWithMargin>
      </ButtonContainer>
    </PageContainer>
  );
};

export default HomePage;
