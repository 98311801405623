import React from 'react';
import styled from 'styled-components';

import Lottie from 'react-lottie';
import data from '../../../assets/data/index.json';
import BOT_ANIMATION from '../../../assets/animations/bot-animation.json';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const WarningText = styled.p`
  color: ${props => props.theme.palette.white[1]};
  font-family: ${props => props.theme.fonts.primary};
  font-size: 1em;
  letter-spacing: 1px;
`;

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: BOT_ANIMATION
};

const WarningPageTextSection = () => {
  const { paragraph } = data.warningPage;
  return (
    <Container>
      <WarningText>{paragraph}</WarningText>
      <Lottie height="50vh" options={defaultOptions} />
    </Container>
  );
};

export default WarningPageTextSection;
