// Hook (use-auth.js)
import React, { useState, useEffect, useContext, createContext } from 'react';
import * as firebase from 'firebase/app';
import 'firebase/auth';

// Add your Firebase credentials
firebase.initializeApp({
  apiKey: 'AIzaSyCYtj9R9M7clzm_SWdT6uthjAeo8GhWsU4',
  authDomain: 'ttt-backend-42a99.firebaseapp.com',
  databaseURL: 'https://ttt-backend-42a99.firebaseio.com',
  projectId: 'ttt-backend-42a99',
  storageBucket: 'ttt-backend-42a99.appspot.com',
  messagingSenderId: '700137452009',
  appId: '1:700137452009:web:b6a7507cc712cf556c1b78',
  measurementId: 'G-RCLEJDNVS1'
});

const authContext = createContext();

// Provider hook that creates auth object and handles state
const useProvideAuth = () => {
  const [user, setUser] = useState(null);
  const provider = new firebase.auth.GoogleAuthProvider();

  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const signin = () => {
    return firebase
      .auth()
      .signInWithPopup(provider)
      .then(response => {
        setUser(response.user);
        return response.user;
      });
  };

  const anonSignIn = async name => {
    const response = await firebase.auth().signInAnonymously();
    const { user } = response;
    setUser({ ...user, displayName: name });
    user.updateProfile({
      displayName: name
    });
  };

  const signout = () => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        setUser(false);
      });
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        setUser(user);
      } else {
        setUser(false);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  // Return the user object and auth methods
  return {
    user,
    signin,
    signout,
    anonSignIn
  };
};

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export const ProvideAuth = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};
