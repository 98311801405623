import React from 'react';

import PageContainer from '../../atoms/PageContainer';
import TopBar from '../../organisms/TopBar';
import GameContainer from '../../templates/GameContainer';

const GamePage = () => (
  <PageContainer>
    <TopBar title="Tic Tac Toe" backbutton />
    <GameContainer />
  </PageContainer>
);

export default GamePage;
