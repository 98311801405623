import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';

import { ProvideAuth } from './functions/useAuth';
import ProtectedRoute from './components/atoms/ProtectedRoute';

import LoginPage from './components/pages/Login';
import HomePage from './components/pages/Home';
import ChooseSide from './components/pages/ChooseSide';
import WarningPage from './components/pages/IntermediateWarning';
import LeaderBoard from './components/pages/Leaderboard';
import GamePage from './components/pages/GamePage';
import theme from './themes';

// Anything defined inside Global style will be applied to all the components
// So, only put highly important styles here
export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  body {
    background-color: ${props => props.theme.palette.background[0]}
  }
`;

// This is your main container for the application
// I've given 25% of width on either side on desktop
export const Container = styled.main`
  margin-left: 25vw;
  margin-right: 25vw;
  @media screen and (max-width: 480px) {
    margin: 0;
  }
`;

const App = () => (
  <ProvideAuth>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <BrowserRouter>
        <Container>
          <Switch>
            <Redirect exact from="/" to="/home" />
            <Route path="/login">
              <LoginPage />
            </Route>
            <ProtectedRoute path="/home">
              <HomePage />
            </ProtectedRoute>
            <ProtectedRoute path="/choose-a-side">
              <ChooseSide />
            </ProtectedRoute>
            <ProtectedRoute path="/warning">
              <WarningPage />
            </ProtectedRoute>
            <ProtectedRoute path="/leaderboard">
              <LeaderBoard />
            </ProtectedRoute>
            <ProtectedRoute path="/game/:id">
              <GamePage />
            </ProtectedRoute>
          </Switch>
        </Container>
      </BrowserRouter>
    </ThemeProvider>
  </ProvideAuth>
);

export default App;
