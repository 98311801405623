import React from 'react';
import styled from 'styled-components';

import data from '../../../assets/data/index.json';

const LoginPageTextSection = () => {
  // This is the title ofthe page, and text below it
  // Taken from our predefined JSON document
  const { title, subTitle } = data.loginPage;

  const TitleText = styled.p`
    margin: 0;
    font-family: ${props => props.theme.fonts.primary};
    font-size: 3em;
    color: ${props => props.theme.palette.white[1]};
  `;

  const SubtitleText = styled.p`
    font-size: 1.2em;
    color: ${props => props.theme.palette.white[1]};
    font-family: ${props => props.theme.fonts.primary};
  `;

  return (
    <>
      <TitleText>{title}</TitleText>
      <SubtitleText>{subTitle}</SubtitleText>
    </>
  );
};

export default LoginPageTextSection;
