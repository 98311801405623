import React from 'react';
import styled from 'styled-components';

const InputComponent = styled.input`
  height: 50px;
  width: 100%;
  border: 0;
  border-radius: 8px;
  background-color: ${props => props.theme.palette.background[1]};
  padding: 15px;
  color: ${props => props.theme.palette.white[1]};
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 1em;
  outline: 0;
  &:focus {
    border: ${props => `1px solid ${props.theme.palette.white[1]}`};
  }
  ::placeholder {
    color: ${props => props.theme.palette.white[1]};
    opacity: 0.4;
  }
`;

const Input = props => {
  const { value, onChange, placeholder = 'Enter a value' } = props;
  return <InputComponent onChange={onChange} value={value} placeholder={placeholder} />;
};

export default Input;
