import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import Button from '../../atoms/Button';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${props => props.theme.palette.background[0]};
  padding: 30px 20px;
  width: 30vw;
  @media screen and (max-width: 480px) {
    width: 80vw;
  }
  border-radius: 20px;
`;

const Title = styled.h1`
  margin: 0;
  font-size: 2em;
  margin: auto;
  margin-bottom: 2em;
  color: ${props => props.theme.palette.white[1]};
`;

const BodyText = styled.h3`
  font-family: ${props => props.theme.fonts.secondary};
  margin: 0px;
  margin-bottom: 16px;
  color: ${props => props.theme.palette.white[1]};
  font-size: 1em;
`;

const GameEndModal = ({ status }) => {
  const history = useHistory();

  const onRematch = () => {
    window.location.reload();
  };

  const onGameEnd = () => {
    history.push('/home');
  };
  return (
    <Container>
      <Title>{status}</Title>
      <BodyText>Do you want to play another game?</BodyText>
      <Button onPress={onRematch} value="Restart" />
      <Button onPress={onGameEnd} secondary value="No Thanks" />
    </Container>
  );
};

export default GameEndModal;
