import { useEffect, useState } from 'react';

const WINNING_CONDITIONS = [
  [0, 1, 2],
  [3, 4, 5],
  [6, 7, 8],
  [0, 3, 6],
  [1, 4, 7],
  [2, 5, 8],
  [0, 4, 8],
  [2, 4, 6]
];

const YOUR_TURN = 'Your Turn';
const USER_VICTORY = 'You got lucky!';
const DRAW_TEXT = 'It is a draw!';
const COMPUTER_VICTORY = 'Haha, you lost!';

const getBooleanBoard = (board, value) => {
  const updatedBoard = board.map(squareValue => {
    if (squareValue === value) {
      return 1;
    }
    if (squareValue) {
      return 0;
    }
    return null;
  });
  return updatedBoard;
};

const haveIWon = (booleanBoard, value) => {
  return WINNING_CONDITIONS.some(condition => {
    const thisIsNotDone = condition.some(conditionIndex => booleanBoard[conditionIndex] !== value);
    return !thisIsNotDone;
  });
};

const areTheyEqual = (firstArray, secondArray) => {
  const theArrayLengthsAreEqual = firstArray.length === secondArray.length;
  const theArrayItemsAreEqual = [...firstArray].sort().every((value, index) => {
    return value === [...secondArray].sort()[index];
  });
  return theArrayLengthsAreEqual && theArrayItemsAreEqual;
};

const isItMyMove = board => {
  const numberOfUserMoves = board.filter(x => x === 1).length;
  const numberOfComputerMoves = board.filter(x => x === 0).length;
  return numberOfUserMoves === numberOfComputerMoves + 1;
};

const isIsStandard = array => {
  const STANDARD_ARRAY = [false, true, true];
  const duplicateArray = array.map(i => {
    return i;
  });
  return areTheyEqual(STANDARD_ARRAY, duplicateArray);
};

const chanceToWinInOneMove = (updatedBoard, value) => {
  // Check if someone can win in one move
  // Mapping the winning conditions,to check if 2/3 of any condition
  // is satisfied
  const computerCanWinWithOneMove = WINNING_CONDITIONS.map(condition => {
    // For each condition, mapping with each item, to see
    // if the same player has placed his card at that box
    const getMappedArray = condition.map(conditionIndex => updatedBoard[conditionIndex] === value);

    const thisComputerCanWinWithOneMove = isIsStandard(getMappedArray);
    if (thisComputerCanWinWithOneMove) {
      const theMissingIndex = getMappedArray.findIndex(i => !i);
      const indexInTheBoard = condition[theMissingIndex];
      // Check if that index is empty
      const thatBoxIsEmpty = updatedBoard[indexInTheBoard] === null;
      if (thatBoxIsEmpty) {
        return indexInTheBoard;
      }
    }
    return false;
  }).filter(i => i);
  return computerCanWinWithOneMove;
};

const assignRandomValue = ({ board, value }) => {
  const newBoard = board.slice();
  const randomIndex = Math.floor(Math.random() * 10); // returns a random integer from 0 to 9
  if (newBoard[randomIndex] === null) {
    newBoard[randomIndex] = value === 'x' ? 'o' : 'x';
    return newBoard;
  }
  return assignRandomValue({ board, value });
};

export const useNextMove = (initialBoard, value) => {
  const [board, setBoard] = useState(initialBoard);
  const [status, setStatus] = useState(YOUR_TURN);
  const [gameEnded, setGameEnded] = useState(false);

  // The effect to run during every game
  useEffect(() => {
    // Converting board of x and o into boolean for easy calculation.
    const updatedBoard = getBooleanBoard(board, value);

    // Check for my turn
    const iCanPlay = isItMyMove(updatedBoard);
    if (!iCanPlay) {
      return;
    }

    setStatus('Thinking...');

    // Check if The computer has won.
    const hasComputerWon = haveIWon(updatedBoard, 0);
    if (hasComputerWon) {
      setStatus(COMPUTER_VICTORY);
      setGameEnded(true);
      return;
    }

    // Check if The user has won.
    const hasUserWon = haveIWon(updatedBoard, 1);
    if (hasUserWon) {
      setStatus(USER_VICTORY);
      setGameEnded(true);
      return;
    }

    // Check if the game is over
    const emptySquares = updatedBoard.filter(i => i === null);
    if (emptySquares.length === 0) {
      // It's filled
      // Game is over
      setStatus(DRAW_TEXT);
      setGameEnded(true);
      return;
    }

    // Check if the computer can win with one move.
    const computerChances = chanceToWinInOneMove(updatedBoard, 0);
    if (computerChances.length) {
      // Yay!
      // Computer can win with one move!
      // Let's make the move
      const newBoard = board.slice();
      newBoard[computerChances[0]] = value === 'x' ? 'o' : 'x';
      setBoard(newBoard);
      setStatus(COMPUTER_VICTORY);
      setGameEnded(true);
      return;
    }

    // Check if the user can win with one move.
    const userChances = chanceToWinInOneMove(updatedBoard, 1);
    if (userChances.length) {
      // Oh No!
      // user can win with one move!
      // Let's fuck him up
      const newBoard = board.slice();
      newBoard[userChances[0]] = value === 'x' ? 'o' : 'x';
      setBoard(newBoard);
      setStatus(YOUR_TURN);
      return;
    }

    // Play a random move
    const newBoard = assignRandomValue({ board, value });
    setBoard(newBoard);
    setTimeout(() => {
      setStatus(YOUR_TURN);
    }, 100);
  }, [initialBoard, status, board, value]);

  const onClick = (valueOfButton, index) => {
    const iCanPlay = status === YOUR_TURN;

    const cellIsFree = !board[index];
    if (!cellIsFree || !iCanPlay || gameEnded) {
      return;
    }

    const newBoard = [...board];
    newBoard[index] = value;
    setBoard(newBoard);
  };

  return { board, onClick, status, gameEnded };
};
