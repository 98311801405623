import React from 'react';
import styled from 'styled-components';

import LOGOUT_ICON from '../../../assets/images/logout-icon.png';
import { useAuth } from '../../../functions/useAuth';

const Image = styled.img`
  height: 24px;
`;

const LogoutButton = () => {
  const auth = useAuth();

  return <Image alt="logout" onClick={auth.signout} src={LOGOUT_ICON} />;
};

export default LogoutButton;
