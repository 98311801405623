import React from 'react';

import PageContainer from '../../atoms/PageContainer';
import TopBar from '../../organisms/TopBar';
import WarningPageTextSection from '../../organisms/WarningPageTextSection';
import WarningPageButtonSection from '../../organisms/WarningPageButtonSection';

const WarningScreen = () => (
  <PageContainer>
    <TopBar backbutton title="Warning" />
    <WarningPageTextSection />
    <WarningPageButtonSection />
  </PageContainer>
);
export default WarningScreen;
