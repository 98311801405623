import React from 'react';
import styled from 'styled-components';

import LogoutButton from '../../atoms/LogoutButton';
import BackButton from '../../atoms/BackButton';

const Container = styled.div`
  margin: 20px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const TitleText = styled.div`
  color: ${props => props.theme.palette.white[1]};
  font-family: ${props => props.theme.fonts.primary};
  font-size: 2.2em;
  font-weight: bold;
  flex: 1;
`;

const TopBar = ({ title, logout = false, backbutton = false }) => {
  return (
    <Container>
      {backbutton && <BackButton />}
      <TitleText>{title}</TitleText>
      {logout && <LogoutButton />}
    </Container>
  );
};

export default TopBar;
