/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import styled from 'styled-components';

import { useAuth } from '../../../functions/useAuth';
import Loader from '../Loader';

const LoadingContainer = styled.div`
  height: 100vh;
  flex: 1;
  display: flex;
  @media screen and (min-width: 480px) {
    width: 50vw;
    justify-content: center;
    align-items: center;
  }
`;

// Creating a protection wrapper around the route component.
// When the user is not logged in, protected route will take them to the login page.
const ProtectedRoute = props => {
  const auth = useAuth();

  // If user is not loggedin, then navigating them to the login page.
  if (auth.user === false) {
    return <Redirect to="/login" />;
  }

  if (!auth.user) {
    return (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    );
  }

  // If user is logged in, we return a normal route component
  return <Route {...props} />;
};

export default ProtectedRoute;
